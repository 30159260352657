import React from 'react';
import NoticeMain from '../../components/Notice/Notice';

const Notice = () => {
  return (
    <>
      <NoticeMain />
    </>
  );
};

export default Notice;
